import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LightModeIcon from '@material-ui/icons/Brightness4Rounded';
import DarkModeIcon from '@material-ui/icons/BrightnessHighRounded';
import AccountCircle from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faEnvelopeOpen, faDoorOpen } from '@fortawesome/free-solid-svg-icons';

import { SettingsDlg, ContactDlg } from '../Dialog';
import { SettingsContext } from '../../context/SettingsContext';

const MainAppBarUserMenu = ({
  loginUser, logoutUser, isAuthenticated,
}) => {
  const [{ materialTheme }, dispatch] = useContext(SettingsContext);

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const logout = () => {
    handleClose();
    logoutUser();
  };

  const login = () => loginUser();

  if (!isAuthenticated) {
    return <Button onClick={login} color="inherit">Login</Button>;
  }

  const openSettings = () => setSettingsOpen(true);

  const closeSettingsDlg = () => {
    setSettingsOpen(false);
    handleClose();
  };

  const openContactMenu = () => {
    setContactOpen(true);
    handleClose();
  };

  const closeContactDlg = () => setContactOpen(false);

  const toggleTheme = () => dispatch({ type: 'toggleTheme' });

  const open = Boolean(anchorEl);
  return (
    <div>
      <IconButton
        aria-owns={open ? 'menu-appbar' : null}
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <ListItemIcon>
            {materialTheme === 'darkTheme' ? <DarkModeIcon /> : <LightModeIcon />}
          </ListItemIcon>
          <ListItemText primary="Dark Mode" />
          <Switch
            checked={materialTheme === 'darkTheme'}
            onChange={toggleTheme}
            color="primary"
            name="darkMode"
            inputProps={{ 'aria-label': 'dark mode checkbox' }}
          />
        </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faDoorOpen} />
          </ListItemIcon>
          Logout
        </MenuItem>
        <MenuItem onClick={openSettings}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faCog} />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={openContactMenu}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faEnvelopeOpen} />
          </ListItemIcon>
          Contact Us
        </MenuItem>
      </Menu>

      <SettingsDlg
        open={settingsOpen}
        handleClose={closeSettingsDlg}
      />
      <ContactDlg
        open={contactOpen}
        handleClose={closeContactDlg}
      />
    </div>
  );
};

MainAppBarUserMenu.propTypes = {
  loginUser: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default MainAppBarUserMenu;
