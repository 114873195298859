import React, {
  Suspense, lazy, useState, useContext,
} from 'react';
import ReactGA from 'react-ga';
import { SnackbarProvider } from 'notistack';
import {
  Route, Redirect, Switch, withRouter, useHistory,
} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Helmet } from 'react-helmet';
import { ConfirmProvider } from 'material-ui-confirm';

import { useAuth0 } from '../../context/auth0-context';
import ErrorBoundary from '../ErrorBoundary';
import MainAppBar from '../MainAppBar';
import GuardedRoute from '../GuardedRoute';
import useComponentWillMount from '../../hooks/useComponentWillMount';
import { SettingsContext } from '../../context/SettingsContext';
import PWABar from './PWABar';
import * as serviceWorker from '../../serviceWorker';
import Drawer from '../../domain/App/Drawer';
import useStyles from './styles';

const HomePage = lazy(() => import('../HomePage'));
const Blog = lazy(() => import('../../domain/CMS/Blog'));
const CollectionPage = lazy(() => import('../../domain/Collection/CollectionPage'));
const AddPage = lazy(() => import('../AddPage'));
const SearchResults = lazy(() => import('../SearchResults'));
const SetDetailsPage = lazy(() => import('../SetDetails/SetDetailsPage'));
const MinifigureDetailsPage = lazy(() => import('../MinifigureDetailsPage'));
const WishListPage = lazy(() => import('../../domain/WishList/WishListPage'));
const PickerPage = lazy(() => import('../../domain/Inventory/PickerPage'));
const BarcodeReader = lazy(() => import('../BarcodeReader/BarcodeReader'));

const DarkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: 'rgb(209, 16, 19)',
    },
    secondary: {
      main: '#f6ec35',
    },
    contrastThreshold: 3,
  },
});

const LightTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiPaper: {
      root: {
        border: '1px solid #999',
      },
      rounded: {
        borderRadius: 5,
      },
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#d11013',
    },
    secondary: {
      main: '#0057A8',
    },
  },
});

const themes = {
  darkTheme: DarkTheme,
  lightTheme: LightTheme,
};

const AppRoutes = () => {
  const classes = useStyles();
  // ReactGA.pageview(window.location.pathname);

  return (
    <ErrorBoundary>
      <div className={classes.wrapper}>
        <Suspense fallback={<div className={classes.wrapper} />}>
          <Switch>
            <Route exact path="/" render={() => (<HomePage />)} />
            <Route exact path="/pick/set" render={() => (<ErrorBoundary><PickerPage /></ErrorBoundary>)} />
            <Route exact path="/search" component={SearchResults} />
            <Route exact path="/set/details/:id" render={() => (<SetDetailsPage />)} />
            <Route exact path="/minifigure/details/:id" render={() => (<MinifigureDetailsPage />)} />
            <Route exact path="/blog/:slug" render={() => (<Blog />)} />
            <GuardedRoute exact path="/barcodereader" component={BarcodeReader} />
            <GuardedRoute path="/collection/:section?" component={CollectionPage} />
            <GuardedRoute path="/wishlist/:section?" component={WishListPage} />
            <GuardedRoute exact path="/add" component={AddPage} />
            <Redirect to="/" />
          </Switch>
        </Suspense>
      </div>
    </ErrorBoundary>
  );
};

const App = () => {
  // Load component styles
  const classes = useStyles();

  // Load theme from settings cotext
  const [{ materialTheme }] = useContext(SettingsContext);

  const [state, setState] = useState({});
  const onServiceWorkerUpdate = (registration) => {
    setState({
      waitingWorker: registration?.waiting,
      newVersionAvailable: true,
    });
  };

  const updateServiceWorker = () => {
    const { waitingWorker } = state;
    if (waitingWorker) waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    setState((oldState) => ({ ...oldState, newVersionAvailable: false }));
    window.location.reload();
  };

  useComponentWillMount(() => {
    serviceWorker.register({ onUpdate: onServiceWorkerUpdate });
  });

  function handleClose() {
    setState({ newVersionAvailable: false });
  }

  // Is this the first time we tried to authenticate user
  const { isLoading } = useAuth0();

  // Initialize google analytics page view tracking
  const history = useHistory();
  history.listen((location) => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="description" content="Brickbase is the site to manage you lego Minifigs and sets, both new or used. Search the complete LEGO catalog for minifigure and set details. " />
        <title>The Brickbase</title>
      </Helmet>
      <MuiThemeProvider theme={themes[materialTheme]}>
        <ConfirmProvider>
          { isLoading ? (<div className={classes.wrapper} />) : (
            <SnackbarProvider maxSnack={3}>
              <Drawer />
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={state?.newVersionAvailable}
                onClose={handleClose}
                ContentProps={{ 'aria-describedby': 'message-id' }}
                message={<span id="message-id">A new update is ready.</span>}
                action={[
                  <>
                    <Button variant="contained" color="primary" onClick={updateServiceWorker}>
                      Update
                    </Button>
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      className={classes.close}
                      onClick={handleClose}
                    >
                      <CloseIcon className={classes.icon} />
                    </IconButton>
                  </>,
                ]}
              />
              <MainAppBar />
              <div className={classes.wrapper}>
                <PWABar />
                <AppRoutes />
              </div>
            </SnackbarProvider>
          )}
        </ConfirmProvider>
      </MuiThemeProvider>
    </>
  );
};

serviceWorker.register();


export default withRouter(App);
export { DarkTheme, LightTheme };
