const styles = (theme) => ({
  pageRoot: {
    backgroundColor: theme.palette.background.default,
    padding: '8px',
    width: '100%',
    flex: 1,
  },
  greenPaper: {
    background: 'linear-gradient(45deg, #2E7D32  30%, #43A047 90%)',
    padding: theme.spacing(2),
    color: 'white',
  },

  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },

  greenButton: {
    background: 'linear-gradient(45deg, #2E7D32  30%, #43A047 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
  },
  redButton: {
    background: 'linear-gradient(45deg, #C62828  30%, #E53935 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
  },
  purpleButton: {
    background: 'linear-gradient(45deg, #AB47BC  30%, #BA68C8 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
  },
  btnDisabled: {
    background: '#777',
    color: '#999',

  },
  slideContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  slideImg: {
    maxWidth: '100%',
    maxHeight: '100%',
    minHeight: '382px',
    objectFit: 'contain',
  },
});

export default styles;
