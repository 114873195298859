import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

// eslint-disable-next-line react/jsx-props-no-spreading
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const AboutDialog = ({ open, onClose }) => (
  <Dialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={onClose}
  >
    <DialogTitle>{`The Brickbase (v${process.env.REACT_APP_VERSION})`}</DialogTitle>
    <DialogContent>
      <div style={{
        float: 'left',
        width: '97.91666667%',
        margin: '0 1.04166667%',
        clear: 'both',
        display: 'block',
        textAlign: 'center',
        fontSize: '12px',
        color: '#848484',
        borderBottom: '1px solid #999',
        paddingBottom: 8,
      }}
      >
        LEGO, the Minifigure and the Brick and Knob configurations are trademarks of the
        LEGO Group, which does not sponsor, authorize or endorse this website
      </div>
      <br />
      <div style={{
        float: 'left',
        width: '97.91666667%',
        margin: '0 1.04166667%',
        clear: 'both',
        display: 'block',
        textAlign: 'center',
        fontSize: '12px',
        color: '#848484',
        paddingTop: 8,
      }}
      >
        The term &apos;Bricklink&apos; is a trademark of Bricklink, Inc. This application uses the
        Bricklink API but is not endorsed or certified by Bricklink, Inc.
      </div>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={onClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

AboutDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AboutDialog;
