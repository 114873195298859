import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CancelIcon from '@material-ui/icons/Cancel';
import DialogTitle from '@material-ui/core/DialogTitle';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import styles from './styles';

function NumberFormatCustom(props) {
  const {
    inputRef, onChange, ...other
  } = props;
  return (
    <NumberFormat
      {...other}
      ref={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

class QuickAddDlg extends Component {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    editSet: null,
    onDelete: null,
  };

  onClose = () => this.props.onClose();
  handleChange = name => event => this.setState({ [name]: event.target.value });
  handleCheckedChange = name => event => this.setState({ [name]: event.target.checked });

  render() {
    const {
      classes, fullScreen, open,
    } = this.props;
    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={this.onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="set-delete-dialog-title">Add Set To Collection</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Set Number"
            type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button variant="raised" className={classes.redButton} onClick={this.onClose} color="primary">
            Cancel
            <CancelIcon className={classes.rightIcon} />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default compose(
  withMobileDialog(),
  withStyles(styles, {
    name: 'AddSetDlg',
  }),
)(QuickAddDlg);
