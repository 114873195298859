import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  flex: {
    flex: 1,
  },
  icon: {
    color: theme.palette.text.secondary,
  },
  nested: {
    paddingLeft: theme.spacing(6),
  },
  group: {
    margin: `${theme.spacing(1)}px`,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    color: 'textPrimary',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      flex: 1,
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
