import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  pwa: {
    margin: 0,
    padding: 2,
    backgroundColor: theme.palette.background.default,
  },
}));

const PWABar = () => {
  const classes = useStyles();
  return (
    <div className={classes.pwa} style={{ flex: 0 }}>
      <pwa-install>Install App</pwa-install>
    </div>
  );
};

export default PWABar;
