import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import ReactImageMagnify from 'react-image-magnify';
import { withSnackbar } from 'notistack';
import { Mutation } from '@apollo/react-components';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import NumberFormat from 'react-number-format';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { v4 as uuidv4 } from 'uuid';

import { convertKeyCodes } from '../../utils';
import styles from './styles';


function NumberFormatCustom(props) {
  const {
    inputRef, onChange, ...other
  } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

class AddSetDlg extends Component {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    fullScreen: PropTypes.bool.isRequired,
    /** Set object */
    set: PropTypes.shape().isRequired,
    /** Collection set object */
    editSet: PropTypes.shape(),
    open: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    title: PropTypes.string.isRequired,
  };

  static defaultProps = {
    editSet: null,
    onDelete: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      setId: this.props.setId,
      isGiveaway: this.props.editSet.isGiveaway,
      hasBox: this.props.editSet.hasBox,
      hasSpareParts: this.props.editSet.hasSpareParts,
      condition: this.props.editSet.condition,
      selectedMfgs: this.props.editSet.minifigures,
      hasInstructions: this.props.editSet.hasInstructions,
      stickersApplied: this.props.editSet.stickersApplied,
      stickersCondition: this.props.editSet.stickersCondition,
      purchasePrice: this.props.editSet.purchasePrice,
      comments: this.props.editSet.comments || '',
    };

    if (this.state.selectedMfgs === null) {
      this.state.selectedMfgs = [];
      props.mfgs.forEach((mfg) => {
        for (let count = 0; count < mfg[0]; count += 1) {
          this.state.selectedMfgs.push({
            _id: uuidv4(),
            mfgId: mfg[1],
            have: true,
          });
        }
      });
    }
  }

  onClose = () => this.props.onClose();

  onDelete = () => this.props.onDelete(this.props.editSet.id);

  onOk = () => {
    const setId = this.props.set.id ? this.props.set.id : this.props.set._id;
    const obj = { setId, ...this.state };
    this.props.onOk(obj);
  }

  selectMfg = id => (event) => {
    const { selectedMfgs } = this.state;
    selectedMfgs.forEach((mfg, num) => {
      if (mfg._id === id) {
        this.setState({
          selectedMfgs: update(selectedMfgs, { [num]: { have: { $set: event.target.checked } } }),
        });
      }
    });
  };

  handleChange = name => event => this.setState({ [name]: event.target.value });
  handleCheckedChange = name => event => this.setState({ [name]: event.target.checked });
  handleStickersApplied = event => this.setState({ stickersApplied: event.target.value });

  render() {
    const {
      classes, fullScreen, open, title, set, onDelete, refetchQueries, addMutation,
      editMutation, minifigureList, enqueueSnackbar,
    } = this.props;
    const { selectedMfgs } = this.state;

    const imgSmall = `https://thebrickbase-ca.imgix.net/SN/xl/${set._id}.jpg?fit=fill&fill=solid&fill-color=999999&w=250&h=250`;
    const imgLarge = `https://thebrickbase-ca.imgix.net/SN/xl/${set._id}.jpg?fit=fill&fill=solid&fill-color=999999&w=500&h=500`;
    const imgWidth = 250;

    //const imgSmall = `/SN/md/${set._id}.jpg`;
    //const imgLarge = `/SN/lg/${set._id}.jpg`;
    const variables = {
      setId: this.state.setId,
      purchasePrice: parseFloat(this.state.purchasePrice),
      condition: this.state.condition,
      isGiveaway: this.state.isGiveaway,
      hasBox: this.state.hasBox,
      hasInstructions: this.state.hasInstructions,
      hasSpareParts: this.state.hasSpareParts,
      stickersApplied: this.state.stickersApplied,
      stickersCondition: this.state.stickersCondition,
      comments: this.state.comments ? this.state.comments : null,
      minifigures: selectedMfgs,
    };

    let mutation = addMutation;
    if (this.props.editSet && this.props.editSet.id !== null) {
      variables.id = this.props.editSet.id;
      mutation = editMutation;
    }
    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={this.onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="set-delete-dialog-title">{convertKeyCodes(title)}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <div className="perimeter">
                <div className={classes.image}>
                  <ReactImageMagnify {...{
                    enlargedImageContainerStyle: { zIndex: 100 },
                    smallImage: {
                      alt: 'Set Image',
                      isFluidWidth: true,
                      src: imgSmall,
                    },
                    largeImage: {
                      alt: '',
                      src: imgLarge,
                      width: imgWidth * 2,
                      height: imgWidth * 2,
                    },
                    isHintEnabled: true,
                  }}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
              <TextField
                fullWidth
                className={classes.textMargin}
                label="Purchase Price"
                value={this.state.purchasePrice}
                onChange={this.handleChange('purchasePrice')}
                id="purchase-price-input"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />

              <FormControl fullWidth className={classes.textMargin}>
                <InputLabel htmlFor="condition-native-simple">Condition</InputLabel>
                <Select
                  native
                  value={this.state.condition}
                  onChange={this.handleChange('condition')}
                  inputProps={{
                    id: 'condition-native-simple',
                  }}
                >
                  <option value="sealed">Sealed</option>
                  <option value="complete">Complete</option>
                  <option value="incomplete">Incomplete</option>
                </Select>
              </FormControl>

              <FormGroup row>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={this.state.isGiveaway}
                      onChange={this.handleCheckedChange('isGiveaway')}
                    />
                  )}
                  label="Lego Giveaway"
                />
              </FormGroup>
 
              <FormControl component="fieldset">
                <FormLabel component="legend">Set Includes</FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={this.state.hasBox}
                        onChange={this.handleCheckedChange('hasBox')}
                      />
                    )}
                    label="Box"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={this.state.hasInstructions}
                        onChange={this.handleCheckedChange('hasInstructions')}
                        value="instructions"
                      />
                    )}
                    label="Instructions"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={this.state.hasSpareParts}
                        onChange={this.handleCheckedChange('hasSpareParts')}
                        value="hasSpareParts"
                      />
                    )}
                    label="Spare Parts"
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Stickers Applied</FormLabel>
                <RadioGroup
                  aria-label="Stickers Applied"
                  name="stickersapplied"
                  value={this.state.stickersApplied}
                  onChange={this.handleStickersApplied}
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel value="N/A" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>

              <FormControl fullWidth className={classes.textMargin}>
                <InputLabel htmlFor="condition-native-simple">Stickers Condition</InputLabel>
                <Select
                  native
                  value={this.state.stickersCondition}
                  onChange={this.handleChange('stickersCondition')}
                  inputProps={{
                    id: 'condition-native-simple',
                  }}
                >
                  <option value="Excellent">Excellent</option>
                  <option value="Good">Good</option>
                  <option value="Fair">Fair</option>
                  <option value="Poor">Poor</option>
                  <option value="Very Poor">Very Poor</option>
                  <option value="" />
                </Select>
              </FormControl>

              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Set Minifigures</FormLabel>
                <FormGroup className={classes.minifigureContainer}>
                  {

                  selectedMfgs.map((mfg) => {
                    const { mfgId, have, _id } = mfg;
                    const mfgDoc = minifigureList.find(doc => doc._id === mfgId);
                    const mfgImg = `https://thebrickbase-ca.imgix.net/MN/xl/${mfgId}.jpg?fit=fill&fill=solid&fill-color=999999&w=250&h=250`;
                    return (
                      <Card
                        className={classes.card}
                        key={_id}
                      >
                        <CardMedia
                          className={classes.mfgImg}
                          component="img"
                          image={mfgImg}
                          title={mfgDoc ? mfgDoc.name : ''}
                        />
                        <Checkbox
                          checked={have}
                          onChange={this.selectMfg(_id)}
                          value={_id}
                        />
                      </Card>
                    );
                  })
                  }
                </FormGroup>
              </FormControl>
              <TextField
                id="comments"
                label="Comments"
                fullWidth
                value={this.state.comments}
                onChange={this.handleChange('comments')}
                margin="normal"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className={classes.redButton} onClick={this.onClose} color="primary">
            Cancel
            <CancelIcon className={classes.rightIcon} />
          </Button>

          <Mutation
            refetchQueries={refetchQueries}
            mutation={mutation}
            variables={variables}
            onCompleted={() => {
              this.onClose();
              enqueueSnackbar(`Added ${variables.setId} to collection`, { variant: 'success' });
            }}
            onError={() => {
              this.onClose();
              enqueueSnackbar(`Error adding ${variables.setId}`, { variant: 'error' });
            }}
          >
            {(collection, { loading }) => (
              <Button
                disabled={loading}
                variant="contained"
                className={classes.greenButton}
                onClick={() => {
                  collection();
                }}
              >
                Save
                <SaveIcon className={classes.rightIcon} />
              </Button>
            )}
          </Mutation>
          { onDelete ? (
            <Button variant="contained" className={classes.redButton} onClick={this.onDelete} >
              Delete
              <DeleteIcon className={classes.rightIcon} />
            </Button>
          )
            : null
          }
        </DialogActions>
      </Dialog>
    );
  }
}

export default compose(
  withMobileDialog(),
  withStyles(styles, {
    name: 'AddSetDlg',
  }),
)(withSnackbar(AddSetDlg));
