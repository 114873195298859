export function convertKeyCodes(str) {
  if (!str) {
    return '';
  }
  return str.replace(/&#(\d{0,4});/g, (fullStr, char) => String.fromCharCode(char));
}

export function toCurrency(numberString) {
  const number = parseFloat(numberString);
  return number.toLocaleString('USD');
}
