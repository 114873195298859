import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    height: '100%',
    backgroundColor: theme.palette.background.default,
  },
  pwa: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
}));

export default useStyles;
