import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ReactImageMagnify from 'react-image-magnify';
import { Mutation } from '@apollo/react-components';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';

import useComponentWillMount from '../../hooks/useComponentWillMount';
import { convertKeyCodes } from '../../utils';
import globalStyles from '../styles';

const useStyles = makeStyles((theme) => ({
  ...globalStyles(theme),
  image: {
    border: '1px solid #999',
  },
  textMargin: {
    marginBottom: theme.spacing(3),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const MfgDlg = ({
  open, onClose, mfgId, name, editMinifigure, refetchQueries, addMutation, updateMutation,
}) => {
  // Load component styles
  const classes = useStyles();

  // Open dialog as fullscreen on smaller screens
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Dialog title
  const [title, setTitle] = useState('');
  useEffect(() => setTitle(`${name} (${mfgId})`), [name, mfgId, setTitle]);

  const [update, setUpdate] = useState();
  useEffect(() => setUpdate(editMinifigure && 'id' in editMinifigure && editMinifigure.id !== null), [editMinifigure, setUpdate]);

  // Set initial values
  const [purchasePrice, setPurchasePrice] = useState('');
  const [comments, setComments] = useState('');

  useComponentWillMount(() => {
    const initPurchasePrice = editMinifigure && editMinifigure.purchasePrice ? editMinifigure.purchasePrice : '0';
    const initComments = editMinifigure && editMinifigure.comments ? editMinifigure.comments : '';

    setPurchasePrice(initPurchasePrice);
    setComments(initComments);
  });

  // Update state values
  const handlePurchasePriceChange = (values) => setPurchasePrice(values.value);
  const handleCommentsChange = (event) => setComments(event.target.value);

  // Image urls
  const mfgImgSmall = `https://thebrickbase-ca.imgix.net/MN/xl/${mfgId}.jpg?fit=fill&fill=solid&fill-color=999999&w=250&h=250`;
  const mfgImgLarge = `https://thebrickbase-ca.imgix.net/MN/xl/${mfgId}.jpg?fit=fill&fill=solid&fill-color=999999&w=500&h=500`;
  const imgWidth = 250;

  // If the set_id is set the minifigure is part of a set and can't be modified.
  const canEdit = editMinifigure && !('setId' in editMinifigure);

  const variables = {
    mfgId,
    purchasePrice,
    comments: comments || null,
  };

  let mutation = addMutation;
  if (update) {
    variables.id = editMinifigure.id;
    mutation = updateMutation;
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="Minifigure Dialog"
    >
      <DialogTitle id="set-delete-dialog-title">{convertKeyCodes(title)}</DialogTitle>
      <DialogContent>
        <Grid container spacing={16}>
          <Grid item xs={6}>
            <div className="perimeter">
              <div className={classes.image}>
                <ReactImageMagnify
                  enlargedImageContainerStyle={{ zIndex: 100 }}
                  smallImage={{
                    alt: 'Minifigure Image',
                    isFluidWidth: true,
                    src: mfgImgSmall,
                  }}
                  largeImage={{
                    alt: '',
                    src: mfgImgLarge,
                    width: imgWidth * 4,
                    height: imgWidth * 4,
                  }}
                  isHintEnabled
                />
              </div>
            </div>
          </Grid>
          { canEdit
            ? (
              <Grid item xs={12} style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
                <NumberFormat
                  fullWidth
                  className={classes.textMargin}
                  label="Purchase Price"
                  value={purchasePrice}
                  onValueChange={handlePurchasePriceChange}
                  customInput={TextField}
                  thousandSeparator
                  prefix="$"
                />
                <TextField
                  id="comments"
                  label="Comments"
                  fullWidth
                  value={comments}
                  onChange={handleCommentsChange}
                  margin="normal"
                />
              </Grid>
            )
            : null }

        </Grid>
      </DialogContent>
      <DialogActions>
        {canEdit
          ? (
            <>
              <Button
                style={{ margin: '0 4px' }}
                className={classes.redButton}
                variant="contained"
                onClick={onClose}
                color="primary"
              >
                Cancel
                <CancelIcon className={classes.rightIcon} />
              </Button>
              <Mutation
                refetchQueries={refetchQueries}
                mutation={mutation}
                variables={variables}
              >
                {(collection) => (
                  <Button
                    style={{ margin: '0 4px' }}
                    className={classes.greenButton}
                    autoFocus
                    variant="contained"
                    onClick={() => {
                      onClose();
                      collection();
                    }}
                  >
                    Save
                    <SaveIcon className={classes.rightIcon} />
                  </Button>
                )}
              </Mutation>
            </>
          )
          : (
            <Button
              style={{ margin: '0 4px' }}
              variant="contained"
              className={classes.greenButton}
              onClick={onClose}
              color="primary"
              autoFocus
            >
              Close
              <CloseIcon className={classes.rightIcon} />
            </Button>
          )}

      </DialogActions>
    </Dialog>
  );
};

MfgDlg.defaultProps = {
  id: null,
  refetchQueries: [],
  editMinifigure: null,
};

MfgDlg.propTypes = {
  id: PropTypes.string,
  editMinifigure: PropTypes.shape(),
  mfgId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  refetchQueries: PropTypes.arrayOf(PropTypes.string),
};

export default MfgDlg;
