import React from 'react';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useRecoilState } from 'recoil';

import drawerOpenState from '../../recoil/drawer';
import Search from '../Search/Search';
import MainAppBarUserMenu from './MainAppBarUserMenu';
import MainAppBarMenu from './MainAppBarMenu';
import { useAuth0 } from '../../context/auth0-context';
import useStyles from './styles';

import '@pwabuilder/pwainstall';

const MainAppBar = () => {
  const auth0 = useAuth0();
  const classes = useStyles();

  // Handle drawer open state
  const [, setOpen] = useRecoilState(drawerOpenState);
  const handleDrawerOpen = () => setOpen(true);

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.flex}>
            <Typography variant="h6" color="inherit" className={classes.sectionDesktop}>
              The Brickbase
            </Typography>
          </div>
          <Search />

          <MainAppBarUserMenu
            loginUser={auth0.loginWithRedirect}
            logoutUser={() => auth0.logout({ returnTo: window.location.origin })}
            isAuthenticated={auth0.isAuthenticated}
          />

          { !auth0.isAuthenticated && <MainAppBarMenu /> }

        </Toolbar>
      </AppBar>
    </div>
  );
};

export default MainAppBar;
