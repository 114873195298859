import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { Mutation } from '@apollo/react-components';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import styles from './styles';

class DeleteSetDlg extends Component {
  static defaultProps = {
    deleteId: null,
    content: '',
  }

  static propTypes = {
    classes: PropTypes.shape().isRequired,
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    refetchQueries: PropTypes.arrayOf(PropTypes.string).isRequired,
    mutation: PropTypes.shape().isRequired,
    deleteId: PropTypes.string,
    content: PropTypes.string,
  };

  onClose = () => this.props.onClose()
  onOk = () => this.props.onOk()

  render() {
    const {
      classes, fullScreen, open, title, refetchQueries, mutation, deleteId, content,
      enqueueSnackbar,
    } = this.props;
    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={this.onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="set-delete-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className={classes.redButton} onClick={this.onClose} color="primary">
            Cancel
          </Button>

          <Mutation
            refetchQueries={refetchQueries}
            mutation={mutation}
            variables={{
              id: deleteId,
            }}
            onCompleted={() => {
              this.onOk();
              enqueueSnackbar(`Deleted ${content}`, { variant: 'success' });
            }}
            onError={() => {
              this.onOk();
              enqueueSnackbar(`Error couldn't delete ${content}`, { variant: 'error' });
            }}
          >
            {deleteCollection => (
              <Button
                variant="contained"
                className={classes.greenButton}
                onClick={() => {
                  deleteCollection();
                }}
              >
                Delete
              </Button>
            )}
          </Mutation>

        </DialogActions>
      </Dialog>
    );
  }
}

export default compose(
  withMobileDialog(),
  withStyles(styles, {
    name: 'DeleteSetDlg',
  }),
)(withSnackbar(DeleteSetDlg));
