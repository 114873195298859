import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import App from '../../../components/App';
import SettingsProvider from '../../../context/SettingsContext';

const AppWrapper = () => (
  <RecoilRoot>
    <SettingsProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SettingsProvider>
  </RecoilRoot>
);

export default AppWrapper;
