import React, { useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import fetchJsonp from 'fetch-jsonp';

const findCountry = async () => {
  const token = 'd9e59e865883eb';
  const url = `https://ipinfo.io?token=${token}`;
  const data = await fetchJsonp(url).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  }).catch(() => null);
  if (data && 'country' in data) {
    return data.country;
  }
  return 'US';
};

const initialState = () => {
  const agGridTheme = localStorage.getItem('agGridTheme') || 'ag-theme-balham-dark';
  const materialTheme = localStorage.getItem('materialTheme') || 'darkTheme';
  const currency = localStorage.getItem('currency') || 'USD';
  const country = localStorage.getItem('country') || findCountry();
  return {
    country,
    currency,
    currencySymbols: {
      CAD: '$',
      EUR: '€',
      GBP: '£',
      USD: '$',
    },
    agGridTheme,
    materialTheme,
  };
};

const setDark = (state) => {
  localStorage.setItem('agGridTheme', 'ag-theme-balham-dark');
  localStorage.setItem('materialTheme', 'darkTheme');
  return {
    ...state,
    agGridTheme: 'ag-theme-balham-dark',
    materialTheme: 'darkTheme',
  };
};

const setLight = (state) => {
  localStorage.setItem('agGridTheme', 'ag-theme-balham');
  localStorage.setItem('materialTheme', 'lightTheme');
  return {
    ...state,
    agGridTheme: 'ag-theme-balham',
    materialTheme: 'lightTheme',
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setCurrency':
      localStorage.setItem('currency', action.value);
      return { ...state, currency: action.value };
    case 'toggleTheme':
      if (state.materialTheme === 'lightTheme') {
        return setDark(state);
      }
      return setLight(state);
    case 'useDark':
      return setDark(state);
    case 'useLight':
      return setLight(state);
    default: throw new Error('Unexpected settings action');
  }
};

const SettingsContext = React.createContext();

const SettingsProvider = ({ children }) => {
  const value = initialState();
  const contextValue = useReducer(reducer, value);
  return (
    <SettingsContext.Provider value={contextValue}>
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useSettings = () => {
  const contextValue = useContext(SettingsContext);
  return contextValue;
};

export { useSettings, SettingsContext };
export default SettingsProvider;
