import globalStyles from '../styles';

const styles = (theme) => ({
  ...globalStyles(theme),
  textMargin: {
    marginBottom: theme.spacing(3),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  image: {
    border: '1px solid #999',
  },
  minifigureContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  mfgImg: {
    width: '75px',
    height: 'auto',
  },
  card: {
    margin: '5px',
  },
});

export default styles;
