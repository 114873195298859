import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AddIcon from '@material-ui/icons/Add';
import StarIcon from '@material-ui/icons/Star';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode, faInfoCircle, faPuzzlePiece } from '@fortawesome/free-solid-svg-icons';
import { useRecoilState } from 'recoil';

import useModal from '../../../hooks/useModal';
import drawerOpenState from '../../../recoil/drawer';
import { useAuth0 } from '../../../context/auth0-context';
import AboutDialog from '../AboutDialog';
import useStyles from './styles';

const Drawer = () => {
  // Load component styles
  const classes = useStyles();

  // Is this the first time we tried to authenticate user
  const auth0 = useAuth0();

  // Handle drawer open state
  const [open, setOpen] = useRecoilState(drawerOpenState);
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  // Manage About dialog
  const [aboutDlgOpen, openAboutDlg, closeAboutDlg] = useModal(false);

  return (
    <SwipeableDrawer
      onOpen={handleDrawerOpen}
      onClose={handleDrawerClose}
      className={classes.drawer}
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        { auth0.user ? (
          <div style={{
            padding: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',
          }}
          >
            <Avatar
              alt={auth0.user.email}
              src={auth0.user.picture}
              className={classes.avatar}
            />
            <Typography variant="subtitle2" color="inherit">
              {auth0.user.name || auth0.user.email}
            </Typography>
          </div>
        ) : null }
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon className={classes.icon} />
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem onClick={handleDrawerClose} button component={Link} to="/">
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem onClick={handleDrawerClose} button component={Link} to="/collection">
          <ListItemIcon><FavoriteIcon /></ListItemIcon>
          <ListItemText primary="My Collection" />
        </ListItem>
        <ListItem onClick={handleDrawerClose} button component={Link} to="/wishlist">
          <ListItemIcon><StarIcon /></ListItemIcon>
          <ListItemText primary="Wish List" />
        </ListItem>
        <ListItem onClick={handleDrawerClose} button component={Link} to="/add">
          <ListItemIcon><AddIcon /></ListItemIcon>
          <ListItemText primary="Add" />
        </ListItem>
        <ListItem onClick={handleDrawerClose} button component={Link} to="/search">
          <ListItemIcon><SearchIcon /></ListItemIcon>
          <ListItemText primary="Search" />
        </ListItem>
        <Divider />
        <ListItem onClick={handleDrawerClose} button component={Link} to="/pick/set">
          <ListItemIcon><FontAwesomeIcon icon={faPuzzlePiece} /></ListItemIcon>
          <ListItemText primary="Set Part Picker" />
        </ListItem>
        <ListItem onClick={handleDrawerClose} button component={Link} to="barcodereader">
          <ListItemIcon><FontAwesomeIcon icon={faBarcode} /></ListItemIcon>
          <ListItemText primary="Barcode Scanner" />
        </ListItem>
        <ListItem onClick={openAboutDlg} button>
          <ListItemIcon><FontAwesomeIcon icon={faInfoCircle} /></ListItemIcon>
          <ListItemText primary="About The Brickbase" />
        </ListItem>
      </List>
      <AboutDialog
        open={aboutDlgOpen}
        onClose={closeAboutDlg}
      />
    </SwipeableDrawer>
  );
};

export default Drawer;
