import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useAuth0 } from '../../context/auth0-context';
import useStyles from './styles';

function GuardedRoute({
  component: Component, path,
}) {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const classes = useStyles();
  return (
    <Route
      exact
      path={path}
      render={(props) => {
        if (!isAuthenticated) {
          return (
            <div className={classes.root}>
              <Paper className={classes.paper} elevation={1}>
                <Typography variant="h5" component="h3">
                  Register as a member!
                </Typography>
                <Typography component="p">
                  By becoming a member, you will be able to manage your collection,
                  create a wishlist, and share stories.
                </Typography>
                <div className={classes.root}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={loginWithRedirect}
                  >
                    Register / Login
                  </Button>
                </div>
              </Paper>
            </div>
          );
        }
        return <Component {...props} />;
      }}
    />
  );
}

GuardedRoute.defaultProps = {
  client: null,
};

GuardedRoute.propTypes = {
  client: PropTypes.shape(),
  component: PropTypes.shape().isRequired,
  path: PropTypes.string.isRequired,
};

export default GuardedRoute;
