import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Formik } from 'formik';
import * as Yup from 'yup';

function ContactDlg({ fullScreen, open, handleClose }) {
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [submitionError, setSubmitionError] = useState(false);

  const contactFormEndpoint = 'https://s97w6q08wh.execute-api.us-east-1.amazonaws.com/dev/email/send';

  function closeDlg() {
    handleClose();
    setSubmitionCompleted(false);
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="Contact Dialog"
    >
      {!isSubmitionCompleted &&
      <React.Fragment>
        <DialogTitle id="form-dialog-title">Contact</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Send us a comment!
          </DialogContentText>
          <Formik
            initialValues={{
              email: '', name: '', comment: '', reason: '20',
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              fetch(contactFormEndpoint, {
                method: 'post',
                body: JSON.stringify(values),
              }).then(() => {
                setSubmitionCompleted(true);
              }).catch((error) => {
                console.error(error);
                setSubmitionCompleted(true);
                setSubmitionError(true);
              });
            }}

            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email()
                .required('Required'),
              name: Yup.string()
                .required('Required'),
              comment: Yup.string()
                .required('Required'),
              reason: Yup.string()
                .required('Required'),
              })}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <FormControl>
                    <InputLabel htmlFor="reason">Reason</InputLabel>
                    <Select
                      native
                      fullWidth
                      value={values.reason}
                      onChange={handleChange('reason')}
                      onBlur={handleBlur('reason')}
                      inputProps={{
                        name: 'Reason',
                        id: 'reason',
                      }}
                    >
                      <option value="Comment">Comment</option>
                      <option value="I would like to report a problem">I would like to report a problem</option>
                      <option value="Request a new feature">Request a new feature</option>
                      <option value="Help me price a lego set">Help me price a lego set</option>
                    </Select>
                  </FormControl>

                  <TextField
                    fullWidth
                    label="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={(errors.name && touched.name) && errors.name}
                    margin="normal"
                    variant="outlined"
                  />

                  <TextField
                    fullWidth
                    error={errors.email && touched.email}
                    label="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={(errors.email && touched.email) && errors.email}
                    margin="normal"
                    variant="outlined"
                  />

                  <TextField
                    fullWidth
                    label="comment"
                    name="comment"
                    value={values.comment}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={(errors.comment && touched.comment) && errors.comment}
                    margin="normal"
                    variant="outlined"
                    color="primary"
                    multiline
                  />
                  <DialogActions>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>

                    <Button
                      type="button"
                      variant="contained"
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}
                    >
                      Reset
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </React.Fragment>
    }
      {isSubmitionCompleted &&
      <React.Fragment>
        <DialogTitle id="form-dialog-title">Thanks!</DialogTitle>
        <DialogContent>
          {submitionError ? (
            <DialogContentText>
              Error sending message
            </DialogContentText>
          ) : (
            <DialogContentText>
              Message successfully sent
            </DialogContentText>
          )
          }
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={closeDlg}
            >
              Continue
            </Button>
          </DialogActions>
        </DialogContent>
      </React.Fragment>}
    </Dialog>
  );
}

ContactDlg.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withMobileDialog()(ContactDlg);
