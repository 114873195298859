import { useState } from 'react';

const useModal = (
  initialMode = false,
  callback = null,
) => {
  // Flag controls if modal is open
  const [modalOpen, setModalOpen] = useState(initialMode);

  // Exactly what it says
  const openModal = () => setModalOpen(true);

  // Close modal and call callback with return value
  const closeModal = (value = null) => {
    setModalOpen(false);
    if (callback) callback(value);
  };

  return [modalOpen, openModal, closeModal];
};

export default useModal;
