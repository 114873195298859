import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { SettingsContext } from '../../context/SettingsContext';

const useStyles = makeStyles((theme) => ({
  flex: {
    flex: 1,
  },
  group: {
    margin: `${theme.spacing(1)}px`,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

function SettingsDlg({ fullScreen, open, handleClose }) {
  const classes = useStyles();
  const [{ currency }, dispatch] = useContext(SettingsContext);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="">Settings</DialogTitle>
      <DialogContent>
        <Typography style={{ margin: '10px' }} variant="h6" color="inherit" className={classes.flex}>
          Currency
        </Typography>
        <RadioGroup
          aria-label="material-theme"
          name="material-theme"
          className={classes.group}
          value={currency}
          onChange={(event) => dispatch({ type: 'setCurrency', value: event.target.value })}
        >
          <FormControlLabel value="CAD" control={<Radio />} label="Canadian Dollar" />
          <FormControlLabel value="USD" control={<Radio />} label="US Dollar" />
          <FormControlLabel value="GBP" control={<Radio />} label="British Pound" />
          <FormControlLabel value="EUR" control={<Radio />} label="Euro" />
        </RadioGroup>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SettingsDlg.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withMobileDialog()(SettingsDlg);
