import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import useStyles from './styles';

const Search = () => {
  const classes = useStyles();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');

  const handleValueChange = (event) => setSearchValue(event.target.value);

  const search = () => {
    history.push({ pathname: '/search', state: { search: searchValue } });
  };

  const onSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      search();
      e.target.blur(); // Blur item so keyboard is hidden on mobile
    }
  };

  return (
    <div className={classes.search}>
      <InputBase
        placeholder="Find Sets and Minifigures"
        value={searchValue}
        onChange={handleValueChange}
        onKeyPress={onSearchKeyPress}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              className={classes.searchIcon}
              onClick={search}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        )}
      />
    </div>
  );
};

export default Search;
