import 'core-js';
import '@webcomponents/webcomponentsjs';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'fast-text-encoding/text';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import ReactGA from 'react-ga';
import AppWrapper from './domain/App/AppWrapper';
import { useAuth0, Auth0Provider } from './context/auth0-context';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import './index.css';

// Init Sentry error tracking
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://409c0563d3284926868f63f830c9dac5@sentry.io/1417353',
  });
}

ReactGA.initialize(process.env.NODE_ENV === 'production' ? 'UA-122658402-1' : 'UA-122658402-2', {
  // debug: process.env.NODE_ENV !== 'production',
});

const AuthProviderContents = () => {
  const auth0 = useAuth0();

  const client = new ApolloClient({
    uri: 'https://thebrickbase-api.vul1.thebrickbase.ca/graphql',
    // uri: 'http://localhost:3030/graphql',
    request: async (operation) => {
      const headers = {};
      try {
        if (auth0.isAuthenticated) {
          headers.authorization = await auth0.getTokenSilently();
        }
        operation.setContext(() => ({ headers }));
      } catch (e) {
        console.error(e);
      }
    },
  });

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <ApolloProvider client={client}>
      <AppWrapper />
    </ApolloProvider>
  );
};

ReactDOM.render(
  <Auth0Provider>
    <AuthProviderContents />
  </Auth0Provider>, document.getElementById('root'),
);
