import React, { useContext, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LightModeIcon from '@material-ui/icons/Brightness4Rounded';
import DarkModeIcon from '@material-ui/icons/BrightnessHighRounded';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';

import { ContactDlg } from '../Dialog';
import { SettingsContext } from '../../context/SettingsContext';

const MainAppBarMenu = () => {
  const [{ materialTheme }, dispatch] = useContext(SettingsContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [contactOpen, setContactOpen] = useState(false);

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function openContactMenu() {
    setContactOpen(true);
    handleClose();
  }

  function closeContactDlg() {
    setContactOpen(false);
  }

  const toggleTheme = () => dispatch({ type: 'toggleTheme' });

  return (
    <>
      <IconButton
        aria-label="More"
        aria-owns={anchorEl ? 'settings-menu' : null}
        aria-haspopup="true"
        color="inherit"
        onClick={handleMenu}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="settings-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <ListItemIcon>
            {materialTheme === 'darkTheme' ? <DarkModeIcon /> : <LightModeIcon />}
          </ListItemIcon>
          <ListItemText primary="Dark Mode" />
          <Switch
            checked={materialTheme === 'darkTheme'}
            onChange={toggleTheme}
            color="primary"
            name="darkMode"
            inputProps={{ 'aria-label': 'dark mode checkbox' }}
          />
        </MenuItem>

        <MenuItem onClick={openContactMenu}>Contact Us</MenuItem>
      </Menu>

      <ContactDlg
        open={contactOpen}
        handleClose={closeContactDlg}
      />
    </>
  );
};

export default MainAppBarMenu;
